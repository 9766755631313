/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
// import service1 from "assets/images/services/service-1.jpg";
import service2 from "assets/images/services/service-2.jpg";
import service3 from "assets/images/services/service-3.jpg";
import service4 from "assets/images/services/service-4.jpg";
import service5 from "assets/images/services/service-5.jpg";
import service6 from "assets/images/services/service-6.jpg";
import service7 from "assets/images/services/service-7.jpg";
import service8 from "assets/images/services/service-8.jpg";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={4}>
            <CenteredBlogCard
              image={service4}
              // title="Get insights on Search"
              // description="Website visitors today demand a frictionless user expericence — especially when using search. Because of the hight standards."
              action={null}
            />
          </Grid>
          <Grid item xs={12} lg={6} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="public"
                    title="Battery servicing"
                    description="All types of Battery(Lithium ion, Lithium Iron Phosphate, Lead acid, BMS, etc) are being repaired."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="payments"
                    title="Charger servicing"
                    description="All types of Charger(Lithium ion, Lithium Iron Phosphate, Lead acid, BMS, etc) are being repaired."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="apps"
                    title="Controller servicing"
                    description="All types of Controller(E-Rickshaw, E-Scooter, E-Cycle, etc) are being repaired."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="3p"
                    title="A-Z Solutions of Electric Vehicle"
                    description="All types of services for Electric Vehicle"
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Container>
        <Grid container spacing={3} alignItems="center" sx={{ mt: { xs: 3, lg: 5 } }}>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={service2}
              // title="Get insights on Search"
              // description=""
              action={null}
            />
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={service3}
              // title="Get insights on Search"
              // description=""
              action={null}
            />
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={service6}
              // title="Get insights on Search"
              // description=""
              action={null}
            />
          </Grid>
        </Grid>
      </Container>
      <Container>
        <Grid container spacing={3} alignItems="center" sx={{ mt: { xs: 3, lg: 5 } }}>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={service5}
              // title="Get insights on Search"
              // description=""
              action={null}
            />
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={service8}
              // title="Get insights on Search"
              // description=""
              action={null}
            />
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={service7}
              // title="Get insights on Search"
              // description=""
              action={null}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
