/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
import Card1 from "assets/images/certificates/Card1.jpeg";
import Card2 from "assets/images/certificates/Card2.jpeg";
import evartllp from "assets/images/certificates/evartllp.jpg";
import NSRCEL from "assets/images/certificates/NSRCEL.jpg";
import STARTUPINDIA from "assets/images/certificates/STARTUPINDIA.jpg";
import STARTUPODISHA from "assets/images/certificates/STARTUPODISHA.jpg";
import Udyam0 from "assets/images/certificates/Udyam0.jpg";
import Udyam1 from "assets/images/certificates/Udyam1.jpg";

function Certificates() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center" sx={{ mt: { xs: 3, lg: 5 } }}>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={Card1}
              // title="Get insights on Search"
              // description=""
              action={null}
            />
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={Card2}
              // title="Get insights on Search"
              // description=""
              action={null}
            />
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={evartllp}
              // title="Get insights on Search"
              // description=""
              action={null}
            />
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={NSRCEL}
              // title="Get insights on Search"
              // description=""
              action={null}
            />
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={STARTUPINDIA}
              // title="Get insights on Search"
              // description=""
              action={null}
            />
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={STARTUPODISHA}
              // title="Get insights on Search"
              // description=""
              action={null}
            />
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={Udyam0}
              // title="Get insights on Search"
              // description=""
              action={null}
            />
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={Udyam1}
              // title="Get insights on Search"
              // description=""
              action={null}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Certificates;
